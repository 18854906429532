body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
}

#title {
    float: left;
}

#loggedin {
    text-align: right;
}

#navigation {
    height: 42px;
}

#back,
#logout {
    background-color: black;
    color: white;
    outline: none;
}

#back {
    float: left;
    margin-right: 20px;
}

#devices {
    display: none;
    min-height: 1102px;
}

#wall {
    display: none;
}

.screenshot {
    height: 72px;
    width: 128px;
}

.warning {
    color: #dc3545;
    font-weight: bold;
}

table.dataTable {
    border-collapse: collapse;
}

table.dataTable tbody tr.odd {
    background-color: #F5F5F5;
}

div.dataTables_filter {
    float: right;
}

.tableheader {
    margin-top: 20px;
}

td.highlight {
    background-color: rgb(248, 215, 218)!important;
}

tr.extra > td {
    padding: 0!important;
}

td.details-control::before {
    top: 9px;
    left: 4px;
    height: 14px;
    width: 14px;
    display: block;
    color: white;
    border: 2px solid white;
    border-radius: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: 'Courier New', Courier, monospace;
    line-height: 15px;
    content: '+';
    background-color: #31b131;
}
td.details-control.invisible::before {
    visibility: hidden;
}
td.details-control.invisible {
    background-color: #c1e3ff;
}

td.details-control.shown::before {
    background-color: #d33333;
    content: '-';
}
td.details-control.shown {
    background-color: #c1e3ff;
}

td.xsmall {
    min-width: 20px;
}
td.small {
    min-width: 110px;
}
td.medium {
    min-width: 140px;
}
td.large {
    min-width: 185px;
}

.wall table.dataTable {
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.led {
    height: 72px;
    width: 128px;
    overflow: hidden;
}

.led img {
    -webkit-transform: scale(13);
    -moz-transform: scale(13);
    -o-transform: scale(13);
    -ms-transform: scale(13);
    transform: scale(13);
    transform-origin: top left;
}

table.smaller {
    transform: scale(.92, 1);
    transform-origin: top left;
}
